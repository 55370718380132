<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Login card -->
          <form
            class="login-form"
            ref="login-form"
            @submit.prevent="submitLogin"
          >
            <div class="card mb-0 shadow-lg rounded">
              <div class="card-body">
                <div class="text-center mb-3">
                  <i
                    class="
                      icon-reading icon-2x
                      text-slate-300
                      border-slate-300 border-3
                      rounded-round
                      p-3
                      mb-3
                      mt-1
                    "
                  ></i>
                  <h5 class="mb-0">Login to your account</h5>

                  <!-- <div class="alert alert-success">
                      Client: client@academiawebsites.com <br/>
                      Password: demo
                  </div> -->
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="text"
                    v-model="loginForm.email"
                    name="email"
                    required
                    class="form-control"
                    placeholder="Username"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-user text-muted"></i>
                  </div>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="password"
                    v-model="loginForm.password"
                    name="password"
                    required
                    class="form-control"
                    placeholder="Password"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-lock2 text-muted"></i>
                  </div>
                </div>

                <base-captcha></base-captcha>

                <div class="form-group d-flex align-items-center">
                  <div class="g-recaptcha" data-sitekey="your_site_key"></div>
                </div>

                <div class="form-group d-flex align-items-center">
                  <div class="form-check mb-0">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        name="remember"
                        class="form-input-styled"
                        checked
                        data-fouc
                      />
                      Remember
                    </label>
                  </div>

                  <router-link :to="{ name: 'ForgotPassword' }" class="ml-auto"
                    >Forgot password?</router-link
                  >
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    :disabled="loginForm.busy"
                    class="btn btn-primary btn-block"
                  >
                    {{ loginForm.busy ? "Loading.." : "Sign in"
                    }}<i class="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <div class="form-group text-center text-muted content-divider">
                  <span class="px-2">Don't have an account?</span>
                </div>

                <div class="form-group">
                  <router-link
                    :to="{ name: 'Register' }"
                    class="btn btn-light btn-block"
                    >Sign up</router-link
                  >
                </div>

                <span class="form-text text-center text-muted"
                  >By continuing, you're confirming that you've read our
                  <a href="#">Terms &amp; Conditions</a> and
                  <a href="#">Cookie Policy</a></span
                >
              </div>
            </div>
          </form>
          <!-- /login card -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      loginForm: new this.$Form({
        email: "",
        password: "",
        token: "",
      }),
    };
  },
  methods: {
    ...mapActions("auth", ["doLogin", "doGetAuthUser"]),
    submitLogin() {
      let grecaptcha
      if (process.env === "production") {
        grecaptcha = window.grecaptcha.getResponse();
      } else {
        grecaptcha = "fasdflk";
      }
      if (!grecaptcha || grecaptcha == "") {
        this.$notify({
          title: "Error",
          text: "Please verify you are not a robot",
          style: "danger",
        });
      } else {
        this.loginForm.token = grecaptcha;
        this.doLogin(this.loginForm)
          .then(() => {
            if (this.$route.query.redirectAfterAuth) {
              document.location.href = this.$route.query.redirectAfterAuth;
            } else {
              document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/home/`;
            }
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          });
      }
    },
  },
  mounted() {
    this.$parent.$emit("loader:hide");
  },
};
</script>
